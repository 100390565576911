var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"custom-accordion",attrs:{"id":"addproduct-accordion"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"me-3"},[_c('div',{staticClass:"avatar-xs"},[_c('div',{staticClass:"avatar-title rounded-circle bg-soft-primary text-primary"},[_vm._v(" 01 ")])])]),_c('div',{staticClass:"media-body overflow-hidden"},[_c('h5',{staticClass:"font-size-16 mb-1"},[_vm._v("Información general")]),_c('p',{staticClass:"text-muted text-truncate mb-0"},[_vm._v(" Completa todos los datos ")])]),_c('i',{staticClass:"mdi mdi-chevron-up accor-down-icon font-size-24"})])]),_c('b-collapse',{attrs:{"data-parent":"#addproduct-accordion","id":"accordion-1","visible":"","accordion":"my-accordion"}},[_c('div',{staticClass:"p-4 border-top"},[_c('form',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.objectData.name),expression:"objectData.name"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitted && _vm.$v.objectData.name.$error,
                        },attrs:{"id":"name","name":"name","type":"text"},domProps:{"value":(_vm.objectData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.objectData, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.objectData.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.objectData.name.required)?_c('span',[_vm._v("Campo requerido")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Rol")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.objectData.role),expression:"objectData.role"}],staticClass:"form-control select2",class:{
                          'is-invalid': _vm.submitted && _vm.$v.objectData.role.$error,
                        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.objectData, "role", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.info_page.roles),function(role){return _c('option',{key:role.id,domProps:{"value":role.id,"textContent":_vm._s(role.text)}})}),0),(_vm.submitted && _vm.$v.objectData.role.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.objectData.role.required)?_c('span',[_vm._v("Campo requerido")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Correo electrónico")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.objectData.email),expression:"objectData.email"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.objectData.email.$error,
                          },attrs:{"type":"email","name":"email","placeholder":"Ingresar Email"},domProps:{"value":(_vm.objectData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.objectData, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.objectData.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.objectData.email.required)?_c('span',[_vm._v("Campo requerido.")]):_vm._e(),(!_vm.$v.objectData.email.email)?_c('span',[_vm._v("Añadir un correo eléctronico válido.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',[_vm._v("Contraseña")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.objectData.password),expression:"objectData.password"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitted && _vm.$v.objectData.password.$error,
                        },attrs:{"type":"password"},domProps:{"value":(_vm.objectData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.objectData, "password", $event.target.value)}}}),(_vm.submitted && _vm.$v.objectData.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.objectData.password.required)?_c('span',[_vm._v("Campo requerido.")]):_vm._e()]):_vm._e()])])])])])])],1)])])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col text-end ms-1"},[_c('a',{staticClass:"btn btn-danger",on:{"click":function($event){$event.preventDefault();return _vm.cancelForm()}}},[_c('i',{staticClass:"uil uil-times me-1"}),_vm._v(" Cancelar ")]),_c('a',{staticClass:"btn btn-success ms-1",on:{"click":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('i',{staticClass:"uil uil-file-alt me-1"}),_vm._v(" Guardar cambios ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }